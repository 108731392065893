

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 5px;
  
}
.feedback-box input{
  width:15rem;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
box-sizing: border-box; 
font-size: 16px;
margin-top: .8%;
}
.field input{
  width: 18rem;
}
.feedback-box input:focus{
  border-color: #007bff;
outline: none; 


}
.feedback-box{
  align-items: center;
 
  padding: 2rem;
}

.modal-content {
  background-color: #fefefe;
  position: absolute;
  bottom: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #888;
  width: 45%;

 border-radius: 5px;
 align-items: center;

}

.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  
  
}
.field textarea{
  width: 90%;
}
.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


@media only screen and (max-width: 400px) {
  .modal-content{
    
    background-color: #fefefe;
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #888;
    width: 80%;

   border-radius: 5px;
   align-items: center;
   
  }
}
@media only screen and (min-width: 900px) {
  .modal-content{
    
    background-color: #fefefe;
    position: absolute;
    bottom: -20%;
    left: 45%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #888;
    width: 30%;

   border-radius: 5px;
   align-items: center;
   
  }
}
