.mantra-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.box {
  margin-bottom: 30px;
}

.lines {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

.line {
  color: orangered;
  font-size: 20px;
  font-weight: 600;
  width: 18%;
  min-height: 30px;
  margin-bottom: 10px;
  border-bottom: 1px dotted #000;
}

.mantrainput {
  padding: 5px;
  width: 250px;
  font-size: 30px;
  border-radius: 8px;
  border: 2px solid #f0f0f0;
  color: #de4620;
}

.main {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.sub_main {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 45%;
  left: 26%;
}

.image {
  width: 30%;
}

.count {
  font-weight: 600;
  margin-left: 3rem;
  margin-top: 0.5rem;
  padding-left: 20px;
}

@media screen and (max-width: 600px) {
  .lines {
    width: 90%;
  }

  .line {
    width: 40%;
  }

  input {
    width: 80%;
  }

  .image {
    width: 90%;
  }

  .sub_main {
    flex-direction: column;
    align-items: center;
    top: 45%;
    left: 10%;
    right: 0;
    margin: auto;
  }

  .count {
    font-weight: 600;
    padding-left: 0;
    margin-left: -1rem;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 900px) {
  .sub_main {

    left: 37%;
  }
}