html, body {
    height: 100%;
    margin: 0;
  }
.testimonials-div{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.testimonials-child1-div{
    width: 60%;
    margin-left: 7%;
    margin-right: 5%;
   font-family: 'Times New Roman', Times, serif;
   height: 90%;
   flex: 1;
}

