/* RegisterModal.css */
.signup-page .login-google-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
  
    padding: 10px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
  
    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    }
  
    &:active {
      background-color: #eeeeee;
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
        0 0 0 3px #c8dafc;
    }
  }

.register-modal {
    display: block;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 50%;
    padding: 20px;
    margin: 20px auto 50px;
}

.register-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}

.label::after {
    content: "*";
    color: red;
    position: absolute;
    margin-left:3px;
}

.sigup-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    row-gap: 5px;
}
.sigup-heading h2{
    margin: 0;
    padding: 0;
}
.sigup-heading p{
    text-align: center;
}

.register-box {
    padding: 20px;
    margin:0 !important;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.field {
    width: 70%;
    margin-bottom: 20px;
}

.label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.control {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .register-modal {
    width: 80%;
  }
  
  .field {
    width: 100%;
  }
  
  .button.is-primary {
    width: 100%;
  }
}

.input,
.textarea {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background: #f2f2f2;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

.input:focus,
.textarea:focus {
    border-color: #3273dc;
    border-color: orangered;
    outline-color: rgba(255, 99, 71, 0.7);
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}

.button.is-primary {
    background-color: orangered;
    border-color: transparent;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-control {
  display: flex;
  justify-content: center;
}

.button.is-primary:hover {
    background-color: #ff6347;
}

img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 4px;
}

.line-container {
    display: flex;
    align-items: center;
    text-align: center;
    color: rgb(164, 170, 178);
    width: 80%;
    margin: 20px 0;
  }
  
  .line-container::before,
  .line-container::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000; /* Adjust the color as needed */
    border-bottom-color: rgb(194, 200, 208);
  }
  
  .line-container::before {
    margin-right: 10px;
  }
  
  .line-container::after {
    margin-left: 10px;
  }
  
  .line-text {
    display: inline-block;
    padding: 0 10px; /* Adjust the spacing as needed */
    background: #fff; /* Adjust the background color to match your page background */
  }
  