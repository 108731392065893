.mantralekhanFooter {
    color: white;
}

.mantralekhanFooter a {
    color: white;
    text-decoration: none;
}

.mantralekhanFooter a:hover {
    color: white;
    text-decoration: underline;
}

.socialIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .socialIcon:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  .socialIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #ffffff;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .socialIcon:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  .playStoreLink img {
    transition: transform 0.3s ease-in-out;
  }
  
  .playStoreLink img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  
  