li{
  cursor: pointer;
}
.navbar {
  background-color: #de4620;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  color: #fff;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.nav-logo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  align-items: center;
  margin-bottom: 0 !important;
  width: 100%;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.logo-image {
  height: 8vh;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
}

.nav-links:hover {
  color: #ffdd40;
}

.nav-item .active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
  border-radius: 5px;
}

.nav-icon {
  display: none;
  color: #f5b921;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 1350px) and (min-width: 1300px) {
  .nav-container {
    max-width: 1300px; 
  }

  .nav-menu {
    padding: 0;
    margin-right: 1rem; 
  }

  .nav-item {
    margin-right: 0.5rem; 
  }

  .nav-links {
    font-size: 1rem; 
    padding: 0.4rem 0.8rem; 
  }
}
@media screen and (max-width: 1300px) {
  .nav-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    width: 100%;
    background-color: #de4620;
    z-index: 1;
  }

  .nav-item {
    margin: 10px 0;
  }

  .nav-links {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
    color: #ffdd40;
  }
}

@media screen and (max-width: 480px) {
  .nav-container {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .nav-logo {
    font-size: 1.5rem;
    margin-left: 0;
  }

  .nav-icon {
    display: block;
    font-size: 1.8rem;
    position: relative;
    right: 0;
    top: 25px;
  }

  .nav-menu {
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    background-color: #de4620;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .nav-links {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
}





