 /* my changes 3 */
 .edit-main {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f7f9fc;
  padding: 20px;
  align-items: center;
  margin-top: -25px;
}

.edit {
  width: 40%; 
  max-width: 500px; 
  margin: auto;
  padding: 20px; 
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.edit-image {
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 0%;
  margin-bottom: 20px;
}

.edit-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10%;
  margin-bottom: 10px;
  border: 3px solid #e0632e;
}

.edit-image input[type="file"] {
  display: none;
}

.edit-image button {
  font-size: 14px;
  color: #d66b3a;
  height: auto;
  border: 1px solid #d66b3a;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s ease;
  margin-top: 0%;
}

.Upload-New-Picture {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.size {
  color: #a9a9a9;
  position: relative;
  left: 2%;
  font-size: xx-small;
}

.edit-user-details form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.edit-user-details label {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin-bottom: 5px;
}

.input-container {
  position: relative;
  margin-bottom: 5px;
}

.edit-user-details input[type="text"],
.edit-user-details input[type="email"],
.edit-user-details input[type="tel"] {
  width: 100%;
  padding: 10px 40px 10px 10px;
  font-size: 16px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.edit-user-details input:focus {
  border-color: #d66b3a;
  outline: none;
}

.edit-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #e9804f;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: #e77a3b;
}

.edit-icon-upload {
  margin-left: 8px;
  margin-right: 6px;
}

.edit-icon-upload:hover {
  color: #e77a3b;
}

.edit-user-details button[type="submit"] {
  padding: 10px 15px;
  font-size: 16px;
  background-color: #e85935;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 10px;
}

.edit-user-details button[type="submit"]:hover {
  background-color: #de4620;
}

/* Toast container */
.toast-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.input-container {
  position: relative;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  width: 100%;
  padding: 0px;
  padding-right: 30px;
}

.edit-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #de4620;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-name {
  font-weight: bold;
  color: #000;
  font-size: 16px;
  margin: -2px;
}

.user-email {
  font-weight: normal;
  color: #a9a9a9;
  font-size: 14px;
  margin: -2px;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .edit {
    width: 70%; /* Adjust form width for tablets */
  }

  .edit-image img {
    width: 80px; /* Smaller profile image for tablets */
    height: 80px;
  }

  .edit-user-details input[type="text"],
  .edit-user-details input[type="email"],
  .edit-user-details input[type="tel"] {
    font-size: 14px; /* Smaller font size for inputs */
    padding: 8px 30px 8px 8px;
  }

  .edit-image button {
    font-size: 12px; /* Smaller button font */
  }

  .details {
    gap: 3px; /* Reduce gap between name and email */
  }
}

/* Mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .edit {
    width: 90%; /* Full-width for smaller screens */
  }

  .edit-image {
    flex-direction: column; /* Stack image and button vertically */
    align-items: center;
    gap: 10px;
  }

  .edit-image img {
    width: 70px; /* Adjust profile image size */
    height: 70px;
  }

  .edit-image button {
    width: auto; /* Prevent full width for button */
    font-size: 12px;
    padding: 5px 10px;
  }

  .edit-user-details input[type="text"],
  .edit-user-details input[type="email"],
  .edit-user-details input[type="tel"] {
    font-size: 12px;
    padding: 6px 25px 6px 6px;
  }

  .user-name {
    font-size: 14px;
  }

  .user-email {
    font-size: 12px;
  }

  .edit-user-details button[type="submit"] {
    font-size: 14px;
    padding: 8px 10px;
  }
}


