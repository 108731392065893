.bottom-parent {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  width: 100%;
  background-color: #de4620;
  border-radius: 5px;
}
.bottom-child2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.bottom-text {
  font-weight: 600;
}
.bottom-child1 {
  padding: 0.5rem;
  color: white;
  margin-left: 5%;
}
.bottom-child2 button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 2%;
}
@media only screen and (max-width: 700px) {
  .bottom-child2 {
    margin-left: 0%;
  }
  .bottom-child1 {
    margin-left: 5%;
  }
}
